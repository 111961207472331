<template>
  <div class="flex justify-center items-center h-screen w-full bg-primary-dark">
    <h1 class="text-white">
      Oops something went wrong
    </h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
